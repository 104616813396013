import React from 'react';

import PropTypes from 'prop-types';
import classNames from 'classnames';
import i18n from '../../i18n';
import styles from './ExtendedByline.module.scss';
import { ucFirst } from '../../utils/caseconverters';
import Byline from '../Byline';
import Button from '../Button';

const ExtendedByline = ({
    author = null,
    color = 'blue50',
    size = 'small',
}) => {
    if (!author || !author.name.length) {
        return null;
    }
    return (
        <article className={styles['ExtendedByline']}>
            <div
                className={classNames(
                    styles['ExtendedByline__Background'],
                    styles['ExtendedByline__Background--' + ucFirst(color)],
                    styles['ExtendedByline--' + ucFirst(size)]
                )}>
                <div className={styles['ExtendedByline__BylineContainer']}>
                    <Byline
                        image={author.image}
                        bylineColor="dark"
                        author={{
                            ...author,
                            name: author.name,
                        }}
                        size="medium"
                    />
                </div>

                {author.text && (
                    <section
                        className={styles['ExtendedByline__TextContainer']}>
                        <strong className={styles['ExtendedByline__Headline']}>
                            {i18n.t('ExtendedByline.authorTextHeader')}
                        </strong>

                        <p className={styles['ExtendedByline__Text']}>
                            {author.text}
                        </p>

                        {author.href && (
                            <Button
                                text={i18n.t('ExtendedByline.authorLinkText')}
                                type="tertiary"
                                icon="arrow"
                                iconColor="black"
                                iconSize="large"
                                buttonColor="black"
                                href={author.href}
                            />
                        )}
                    </section>
                )}
            </div>
        </article>
    );
};

ExtendedByline.propTypes = {
    card: PropTypes.object,
    author: PropTypes.object,
    headline: PropTypes.string,
    text: PropTypes.string,
    color: PropTypes.oneOf(['orange50', 'blue50', 'purple50', 'green50']),
    size: PropTypes.oneOf(['small', 'large']),
    href: PropTypes.string,
};

export default ExtendedByline;
