import React, { useEffect, useState } from 'react';

import i18n from '../../i18n';
import PropTypes from 'prop-types';
import styles from './ArticleEntry.module.scss';
import Button from '../Button';
import LabeledIcon from '../LabeledIcon';
import classNames from 'classnames';
import {
    isPageIdSaved,
    saveOrRemoveId,
    MY_LIST_KEY_OBJECTS,
    MY_LIST_KEY_GUIDES,
} from '../../utils/MyListStorage';
import ShareModal from '../ShareModal';

const ArticleEntry = ({
    id,
    pageId,
    pageType,
    categories = null,
    title = '',
    preamble = null,
    publishDate = null,
    displayActions = true,
    smallFonts = false,
}) => {
    const [modalIsOpen, setModalOpen] = useState(false);
    const handleClose = () => {
        setModalOpen(false);
    };
    const handleOpenModal = () => {
        setModalOpen(true);
    };

    if (pageId != null) {
        id = pageId;
    }

    const [isSaved, setSavedStatus] = useState();
    const storageKey =
        pageType === 'GuidePage' ? MY_LIST_KEY_GUIDES : MY_LIST_KEY_OBJECTS;
    useEffect(() => {
        setSavedStatus(isPageIdSaved(storageKey, id));
    }, []);

    const handleSaveClick = () => {
        let isSaved = saveOrRemoveId(storageKey, id, title, categories);
        setSavedStatus(isSaved);
    };

    const saveButtonText = i18n.t(isSaved ? 'generic.saved' : 'generic.save');

    return (
        <article
            className={classNames(styles['ArticleEntry'], {
                [styles['ArticleEntry--SmallFonts']]: smallFonts,
            })}>
            {title && (
                <>
                    {categories && (
                        <strong className={styles['ArticleEntry__Label']}>
                            <span className="sr-only">
                                {i18n.t('generic.categories')}
                                {': '}
                            </span>
                            {categories.map((x) => x.title).join(', ')}
                        </strong>
                    )}

                    <h1 className={styles['ArticleEntry__Title']}>{title}</h1>

                    {publishDate && (
                        <p className={styles['ArticleEntry__PublishDate']}>
                            <span className="sr-only">
                                {i18n.t('generic.publishDate')}
                                {': '}
                            </span>
                            {publishDate}
                        </p>
                    )}

                    {displayActions && (
                        <div className={styles['ArticleEntry__MetaContainer']}>
                            <div
                                className={
                                    styles['ArticleEntry__LabeledIconWrapper']
                                }>
                                <LabeledIcon
                                    type="share"
                                    onClick={handleOpenModal}
                                    label={i18n.t('generic.share')}
                                    color="black800"
                                    isVertical={true}></LabeledIcon>
                            </div>

                            <Button
                                type="secondary"
                                onClick={handleSaveClick}
                                isClicked={isSaved}
                                icon="bookmark"
                                iconColor="black800"
                                iconSize="medium"
                                text={saveButtonText}
                            />
                        </div>
                    )}

                    <p className={styles['ArticleEntry__Preamble']}>
                        {preamble}
                    </p>
                </>
            )}

            <ShareModal
                title={title}
                isOpen={modalIsOpen}
                handleClose={handleClose}
            />
        </article>
    );
};

ArticleEntry.propTypes = {
    categories: PropTypes.array,
    title: PropTypes.string.isRequired,
    preamble: PropTypes.string,
    publishDate: PropTypes.string,
};

export default ArticleEntry;
